import request from 'superagent';
import { GET_STORY } from './types';
import StoryChief from '../../storychief/index';
import updateStatusError from '../../storychief/actions/updateStatusError';

export function getPreviewStoryAsync(story) {
  return {
    type: GET_STORY,
    payload: story,
  };
}

export default function getPreviewStory(endpoint) {
  return (dispatch) => {
    request
      .get(endpoint)
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-CSRF-TOKEN', StoryChief.csrfToken)
      .set('X-Socket-ID', window.Echo.socketId())
      .end((err, res) => {
        if (res && res.ok) {
          const story = res.body;
          // reverse comments order
          if (story.comments) {
            story.comments = story.comments.reverse();
          }
          dispatch(getPreviewStoryAsync(story));
        } else {
          dispatch(updateStatusError(res, err));
        }
      });
  };
}
