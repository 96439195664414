import request from 'superagent';
import { UPDATE_STORY_FEATURED_IMAGE } from './types';
import processingStoryProperty from './processingStoryProperty';
import processingStoryFeaturedImage from './processingStoryFeaturedImage';
import clearStoryStatusProperty from './clearStoryStatusProperty';
import StoryChief from '../../storychief/index';
import updateStatusError from '../../storychief/actions/updateStatusError';
import updateStoryHasUnconfirmedChanges from '@/stories/actions/updateStoryHasUnconfirmedChanges';

function updateStoryFeaturedImageAsync(image) {
  return {
    type: UPDATE_STORY_FEATURED_IMAGE,
    payload: image,
  };
}

export default function (storyId, image) {
  const property = 'featured_image';
  return (dispatch, getState) => {
    dispatch(processingStoryProperty(property));
    dispatch(processingStoryFeaturedImage());
    request
      .put(`${StoryChief.apiBasePath}/stories/${storyId}`)
      .set('X-Lock-Token', getState().lock.token)
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-CSRF-TOKEN', StoryChief.csrfToken)
      .set('X-Socket-ID', window.Echo.socketId())
      .send({
        featured_image: image,
      })
      .end((err, res) => {
        if (res && res.ok) {
          dispatch(updateStoryFeaturedImageAsync(image));
          dispatch(clearStoryStatusProperty(property));
          dispatch(updateStoryHasUnconfirmedChanges(res.body.has_unconfirmed_changes));
        } else {
          dispatch(updateStatusError(res, err));
        }
      });
  };
}
