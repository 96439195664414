import request from 'superagent';
import { UPDATE_STORY_SEO_TITLE } from './types';
import processingStoryProperty from './processingStoryProperty';
import clearStoryStatusProperty from './clearStoryStatusProperty';
import updateStoryAnalysis from './updateStoryAnalysis';
import StoryChief from '../../storychief/index';
import updateStatusError from '../../storychief/actions/updateStatusError';
import updateStoryHasUnconfirmedChanges from '@/stories/actions/updateStoryHasUnconfirmedChanges';

export function updateStorySeoTitleAsync(seo_title) {
  return {
    type: UPDATE_STORY_SEO_TITLE,
    payload: seo_title,
  };
}

export default function updateStorySeoTitle(storyId, seo_title) {
  const property = 'seo_title';
  return (dispatch, getState) => {
    dispatch(processingStoryProperty(property));
    request
      .put(`${StoryChief.apiBasePath}/stories/${storyId}`)
      .set('X-Lock-Token', getState().lock.token)
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-CSRF-TOKEN', StoryChief.csrfToken)
      .set('X-Socket-ID', window.Echo.socketId())
      .send({
        seo_title,
      })
      .end((err, res) => {
        if (res && res.ok) {
          dispatch(updateStorySeoTitleAsync(seo_title));
          dispatch(clearStoryStatusProperty(property));
          dispatch(updateStoryAnalysis());
          dispatch(updateStoryHasUnconfirmedChanges(res.body.has_unconfirmed_changes));
        } else {
          dispatch(updateStatusError(res, err));
        }
      });
  };
}
