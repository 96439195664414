import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useStoryEditorSidebars from '@/stories/hooks/useStoryEditorSidebars';

/**
 * Prevent sidebar from overlapping story content
 * @returns {number}
 */
export default function useStoryEditorOffset() {
  const [offset, setOffset] = useState(0);
  const location = useLocation();
  const storyEditorSidebars = useStoryEditorSidebars();
  const activeSidebar = storyEditorSidebars.data?.[0]?.id;

  useEffect(() => {
    const sidebarNode = document.querySelector('.sidebar');

    if (sidebarNode && activeSidebar) {
      setOffset(sidebarNode.clientWidth);
    } else {
      setOffset(0);
    }
  }, [activeSidebar, location]);

  return offset;
}
