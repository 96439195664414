import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

const propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isRoot: PropTypes.bool,
};

const defaultProps = {
  isRoot: false,
};

function StoryEditNavigationItem({ url, label, isRoot }) {
  const { pathname } = useLocation();
  const isActive = pathname === url || (pathname === '/' && isRoot);

  return (
    <Link replace className={classNames({ active: isActive })} to={url}>
      {label}
    </Link>
  );
}

StoryEditNavigationItem.propTypes = propTypes;
StoryEditNavigationItem.defaultProps = defaultProps;

export default StoryEditNavigationItem;
