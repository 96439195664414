import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { convertFromRaw } from 'draft-js';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Icon } from '@iconify-icon/react';
import Select from '@/storychief/components/Select';
import TextEditor from '../../../storychief/components/editors/TextEditor';
import StoryTeaserCardSeo from '../StoryTeaserCardSeo';
import { getModelIsLocked } from '@/presence/selectors';
import StoryChief from '../../../storychief';
import debounce from '../../../storychief/utils/debounce';
import ImageFieldType from '../../../storychief/components/custom-fields/ImageFieldType';
import updateStoryExcerpt from '../../actions/updateStoryExcerpt';
import updateStorySeoTitle, { updateStorySeoTitleAsync } from '../../actions/updateStorySeoTitle';
import updateStorySeoDescription from '../../actions/updateStorySeoDescription';
import updateStorySeoSlug from '../../actions/updateStorySeoSlug';
import updateStoryFeaturedImage from '../../actions/updateStoryFeaturedImage';
import updateStoryFeaturedImageAlt from '../../actions/updateStoryFeaturedImageAlt';
import setStoryDueDate from '../../actions/setStoryDueDate';
import getEditorFirstImage from '@/editor/utils/getEditorFirstImage';
import convertTextToSlug from '../../../storychief/utils/convertTextToSlug';
import DateRangePicker from '../../../storychief/components/DateRangePicker';
import getTitlePlainText from '@/editor/utils/getTitlePlainText';
import useModal from '@/modals/hooks/useModal';
import HelpLink from '@/storychief/components/HelpLink';
import omitGraphQlProps from '@/storychief/utils/omitGraphQlProps';
import addStoryTagAsync from '../../actions/addStoryTagAsync';
import addStoryCategoryAsync from '../../actions/addStoryCategoryAsync';
import addStoryAuthorAsync from '../../actions/addStoryAuthorAsync';
import updateStoryHasUnconfirmedChanges from '@/stories/actions/updateStoryHasUnconfirmedChanges';
import usePreview from '@/storychief/hooks/usePreview';
import useModelSignKey from '@/storychief/hooks/useModelSignKey';
import getDateObjectWithTimezone from '@/date/getDateObjectWithTimezone';
import useLanguageTranslationsMutation from '@/storychief/components/DropdownLanguageLabel/hooks/useLanguageTranslationsMutation';
import useLanguageTranslationsQuery from '@/storychief/components/DropdownLanguageLabel/hooks/useLanguageTranslationsQuery';

const propTypes = {
  accessToken: PropTypes.string,
  story: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    is_private: PropTypes.bool,
    excerpt: PropTypes.string,
    due_at: PropTypes.string,
    slug: PropTypes.string,
    featured_image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      status: PropTypes.string,
    }),
    language: PropTypes.string,
    custom_fields: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        description: PropTypes.string,
        default: PropTypes.string,
        option_src: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
          }),
        ),
      }),
    ),
    published: PropTypes.bool,
  }).isRequired,
  lock: PropTypes.shape({ token: PropTypes.string }),
  storyId: PropTypes.number.isRequired,
  storyTitle: PropTypes.string.isRequired,
  storySeoTitle: PropTypes.string.isRequired,
  storySeoDescription: PropTypes.string.isRequired,
  storySeoSlug: PropTypes.string.isRequired,
  storyKeywords: PropTypes.arrayOf(PropTypes.shape({})),
  languages: PropTypes.arrayOf(PropTypes.shape),
  readOnly: PropTypes.bool.isRequired,
  setStoryDueDate: PropTypes.func.isRequired,
  updateStoryExcerpt: PropTypes.func.isRequired,
  updateStoryFeaturedImage: PropTypes.func.isRequired,
  updateStoryFeaturedImageAlt: PropTypes.func.isRequired,
  updateStorySeoTitle: PropTypes.func.isRequired,
  updateStorySeoDescription: PropTypes.func.isRequired,
  updateStorySeoSlug: PropTypes.func.isRequired,
  addStoryTagAsync: PropTypes.func.isRequired,
  addStoryCategoryAsync: PropTypes.func.isRequired,
  addStoryAuthorAsync: PropTypes.func.isRequired,
  updateStoryHasUnconfirmedChanges: PropTypes.func.isRequired,
};
const defaultProps = {
  accessToken: null,
  lock: null,
  languages: [],
  storyKeywords: [],
};

const storyAuthorFragment = gql`
  fragment DetailsBlockStoryAuthor on Story {
    __typename
    id
    author {
      __typename
      id
      firstname
      lastname
      fullname
      email
    }
  }
`;

const storyCategoriesFragment = gql`
  fragment DetailsBlockStoryCategories on Story {
    __typename
    id
    categories {
      __typename
      id
      name
      slug
    }
  }
`;

const storyTagsFragment = gql`
  fragment DetailsBlockStoryTags on Story {
    __typename
    id
    tags {
      __typename
      id
      name
      slug
    }
  }
`;

const fragments = {
  storyAuthor: storyAuthorFragment,
  storyCategories: storyCategoriesFragment,
  storyTags: storyTagsFragment,
  story: gql`
    fragment DetailsBlockStory on Story {
      ...DetailsBlockStoryAuthor
      ...DetailsBlockStoryCategories
      ...DetailsBlockStoryTags
    }
    ${storyAuthorFragment}
    ${storyCategoriesFragment}
    ${storyTagsFragment}
  `,
};

const DETAILS_BLOCK_STORY_PRIVATE_QUERY = gql`
  query DetailBlockStory($id: ID!) {
    story(id: $id) {
      __typename
      id
      ...DetailsBlockStoryAuthor
      ...DetailsBlockStoryCategories
      ...DetailsBlockStoryTags
    }
  }
  ${storyAuthorFragment}
  ${storyCategoriesFragment}
  ${storyTagsFragment}
`;

const DETAILS_BLOCK_AUTHORS_PRIVATE_QUERY = gql`
  query DetailBlockStoryAuthors {
    authors {
      __typename
      id
      firstname
      lastname
      fullname
      email
    }
  }
`;

const DETAILS_BLOCK_CATEGORIES_PRIVATE_QUERY = gql`
  query DetailBlockStoryCategories {
    categories {
      __typename
      id
      name
      slug
    }
  }
`;

const DETAILS_BLOCK_TAGS_PRIVATE_QUERY = gql`
  query DetailBlockStoryTags {
    tags {
      __typename
      id
      name
      slug
    }
  }
`;

const DETAILS_BLOCK_STORY_PUBLIC_QUERY = gql`
  query DetailBlockPublicStory($id: ID!) {
    story: publicStory(id: $id) {
      __typename
      id
      ...DetailsBlockStoryAuthor
      ...DetailsBlockStoryCategories
      ...DetailsBlockStoryTags
    }
  }
  ${storyAuthorFragment}
  ${storyCategoriesFragment}
  ${storyTagsFragment}
`;

const DETAILS_BLOCK_CREATE_CATEGORY = gql`
  mutation CreateStoryCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      __typename
      id
      name
      slug
    }
  }
`;

const DETAILS_BLOCK_UPDATE_STORY_CATEGORIES_MUTATION = gql`
  mutation UpdateStoryCategories($input: UpdateStoryCategoriesInput!) {
    updateStoryCategories(input: $input) {
      ...DetailsBlockStoryCategories
    }
  }
  ${fragments.storyCategories}
`;

const DETAILS_BLOCK_CREATE_TAG = gql`
  mutation CreateStoryTag($input: CreateTagInput!) {
    createTag(input: $input) {
      __typename
      id
      name
      slug
    }
  }
`;

const DETAILS_BLOCK_UPDATE_STORY_TAGS_MUTATION = gql`
  mutation UpdateStoryTagsMutation($input: UpdateStoryTagsInput!) {
    updateStoryTags(input: $input) {
      ...DetailsBlockStoryTags
    }
  }
  ${fragments.storyTags}
`;

const DETAILS_BLOCK_UPDATE_STORY_AUTHOR_MUTATION = gql`
  mutation UpdateStoryAuthorMutation($input: UpdateStoryInput!) {
    updateStory(input: $input) {
      ...DetailsBlockStoryAuthor
    }
  }
  ${fragments.storyAuthor}
`;

const getSeoAlertMsg = (len, type = 'characters') =>
  `To get the best SEO results, we recommend keeping the text length below ${len} ${type}.`;

function DetailsBlock(props) {
  // Hooks
  const { preview } = usePreview();
  const modelSignKey = useModelSignKey({ typename: 'Story', id: props.storyId });
  const dispatch = useDispatch();

  const {
    data: { story } = {
      story: { categories: [], tags: [] },
    },
    loading: loadingStory,
  } = useQuery(!preview ? DETAILS_BLOCK_STORY_PRIVATE_QUERY : DETAILS_BLOCK_STORY_PUBLIC_QUERY, {
    variables: {
      id: props.storyId,
    },
    context: preview ? { headers: modelSignKey } : { batch: true },
    credentials: !preview,
  });

  const {
    data: { authors } = { authors: [] },
    loading: loadingAuthors,
    refetch: refetchAuthors,
  } = useQuery(DETAILS_BLOCK_AUTHORS_PRIVATE_QUERY, {
    skip: preview,
    context: {
      batch: true,
    },
  });

  const {
    data: { categories } = { categories: [] },
    loading: loadingCategories,
    refetch: refetchCategories,
  } = useQuery(DETAILS_BLOCK_CATEGORIES_PRIVATE_QUERY, {
    skip: preview,
    context: {
      batch: true,
    },
  });

  const {
    data: { tags } = { tags: [] },
    loading: loadingTags,
    refetch: refetchTags,
  } = useQuery(DETAILS_BLOCK_TAGS_PRIVATE_QUERY, {
    skip: preview,
    context: {
      batch: true,
    },
  });

  // Mutations
  const [createCategory] = useMutation(DETAILS_BLOCK_CREATE_CATEGORY);
  const [createTag] = useMutation(DETAILS_BLOCK_CREATE_TAG);
  const { data: { language: storyLanguage } = {}, loadingLanguageTranslations } =
    useLanguageTranslationsQuery({
      modelId: props.storyId,
      modelType: 'Story',
    });
  const { updateLanguage, loadingUpdateLanguage } = useLanguageTranslationsMutation({
    modelId: props.storyId,
    modelType: 'Story',
  });

  const [updateStoryAuthorMutation, { loading: loadingStoryAuthorMutation }] = useMutation(
    DETAILS_BLOCK_UPDATE_STORY_AUTHOR_MUTATION,
    {
      onCompleted: ({ updateStory }) => {
        props.addStoryAuthorAsync(getFormattedObjectForDispatch(updateStory));
        props.updateStoryHasUnconfirmedChanges(true);
      },
      context: !preview ? { headers: { 'X-Lock-Token': props.lock.token || null } } : null,
    },
  );
  const [updateStoryCategoriesMutation, { loading: loadingStoryCategoriesMutation }] = useMutation(
    DETAILS_BLOCK_UPDATE_STORY_CATEGORIES_MUTATION,
    {
      onCompleted: ({ updateStoryCategories }) => {
        props.addStoryCategoryAsync(
          updateStoryCategories.categories.map((category) =>
            getFormattedObjectForDispatch(category),
          ),
        );
        props.updateStoryHasUnconfirmedChanges(true);
      },
      context: !preview ? { headers: { 'X-Lock-Token': props.lock.token || null } } : null,
    },
  );

  const [updateStoryTagsMutation, { loading: loadingStoryTagsMutation }] = useMutation(
    DETAILS_BLOCK_UPDATE_STORY_TAGS_MUTATION,
    {
      onCompleted: ({ updateStoryTags }) => {
        props.addStoryTagAsync(
          updateStoryTags.tags.map((tag) => getFormattedObjectForDispatch(tag)),
        );
        props.updateStoryHasUnconfirmedChanges(true);
      },
      context: !preview ? { headers: { 'X-Lock-Token': props.lock.token || null } } : null,
    },
  );

  // States
  const seoTitle = props.storySeoTitle;
  const seoDescription = props.storySeoDescription;

  const [seoSlug, setSeoSlug] = useState(props.storySeoSlug);

  // Hooks
  const modal = useModal('AddAuthorModal');

  useEffect(() => {
    if (!props.readOnly && props.story.featured_image.src === '' && props.story.content !== '') {
      const editorImage = getEditorFirstImage(convertFromRaw(JSON.parse(props.story.content)));

      if (editorImage) {
        props.updateStoryFeaturedImage(props.story.id, editorImage);
      }
    }
  }, []);

  // Functions
  function saveSeoValues({ name, value }) {
    switch (name) {
      case 'seoTitle':
        props.updateStorySeoTitle(props.storyId, value);
        break;
      case 'seoDescription':
        props.updateStorySeoDescription(props.storyId, value);
        break;
      case 'seoSlug':
        props.updateStorySeoSlug(props.storyId, value);
        break;
      default:
        break;
    }
  }

  // Callbacks
  const debouncedSaveSeoValues = useCallback(
    debounce((data) => saveSeoValues(data), 1000),
    [],
  );

  function onTextFormControlChange({ target }) {
    if (target.name === 'seoSlug') {
      setSeoSlug(convertTextToSlug(target.value, false));
    }

    debouncedSaveSeoValues(target);
  }

  function onSeoTitleChange(value) {
    dispatch(updateStorySeoTitleAsync(value));

    debouncedSaveSeoValues({
      name: 'seoTitle',
      value,
    });
  }

  function onSeoDescriptionChange(value) {
    debouncedSaveSeoValues({
      name: 'seoDescription',
      value,
    });
  }

  async function createAndGetTag(name) {
    const response = await createTag({
      variables: {
        input: {
          name,
        },
      },
    });

    return response.data.createTag;
  }

  async function handleTagSelectChange(changedTags) {
    const tempNewTag = changedTags.find((t) => t.__isNew__);
    const ids = changedTags.filter((t) => !t.__isNew__).map((_tag) => _tag.id);

    if (tempNewTag) {
      const newTag = await createAndGetTag(tempNewTag.name);

      ids.push(newTag.id);

      refetchTags();
    }

    updateStoryTagsMutation({
      variables: {
        input: {
          id: props.story.id,
          tags: ids,
        },
      },
      context: {
        headers: {
          'X-Lock-Token': props.accessToken,
        },
      },
    });
  }

  async function createAndGetCategory(name) {
    const response = await createCategory({
      variables: {
        input: {
          name,
        },
      },
    });

    return response.data.createCategory;
  }

  async function handleCategorySelectChange(changedCategories) {
    const tempNewCategory = changedCategories.find((c) => c.__isNew__);
    const ids = changedCategories.filter((c) => !c.__isNew__).map((c) => c.id);

    if (tempNewCategory) {
      const newCategory = await createAndGetCategory(tempNewCategory.name);

      ids.push(newCategory.id);

      refetchCategories();
    }

    await updateStoryCategoriesMutation({
      variables: {
        input: {
          id: props.story.id,
          categories: ids,
        },
      },
      context: {
        headers: {
          'X-Lock-Token': props.accessToken,
        },
      },
    });
  }

  function handleAuthorSelectChange(author) {
    if (!author) return;

    updateStoryAuthorMutation({
      variables: {
        input: {
          id: story.id,
          author_id: author.id,
        },
      },
      context: {
        headers: {
          'X-Lock-Token': props.accessToken,
        },
      },
    });
  }

  function handleOnAddAuthorClick() {
    modal.toggle({
      props: {
        onAuthorAdd: () => {
          handleAuthorSelectChange();
          refetchAuthors();
        },
      },
    });
  }

  function handleLanguageSelectChange(value) {
    updateLanguage({
      variables: {
        input: {
          language: value.value,
          model_id: props.storyId,
          model_type: 'Story',
        },
      },
    });
  }

  function handleUpdateStoryExcerpt(text) {
    props.updateStoryExcerpt(props.story.id, text);
  }

  function handleUpdateStoryFeaturedImage(fieldValue) {
    props.updateStoryFeaturedImage(props.story.id, fieldValue.value);
  }

  function handleUpdateStoryFeaturedImageAlt(fieldValue) {
    props.updateStoryFeaturedImageAlt(props.story.id, fieldValue);
  }

  function handleDueDateChange(changedDueDate) {
    props.setStoryDueDate(props.story.id, changedDueDate);
  }

  function handleDueDateClear() {
    props.setStoryDueDate(props.story.id, '');
  }

  function getFormattedObjectForDispatch(object) {
    return omitGraphQlProps({ ...object, id: Number(object.id) });
  }

  // Variables
  let storyTitlePlainText = getTitlePlainText(props.storyTitle);
  storyTitlePlainText = storyTitlePlainText === 'Untitled article' ? '' : storyTitlePlainText;
  const titlePlaceholder = seoTitle || storyTitlePlainText || '';
  const languageOptions = props.languages.slice().map((lang) => ({
    label: lang.label,
    value: lang.code,
  }));
  const dueAt = props.story.due_at ? getDateObjectWithTimezone(props.story.due_at) : null;
  const featuredImageFieldDef = {
    type: 'image',
    name: 'featured_image',
    label: 'Cover image',
    alt: props.story.featured_image.alt,
    important: false,
    default: props.story.featured_image.src,
    saveToDatabase: true,
    isPrivate: props.story.is_private,
    description:
      'The image displayed on overview pages. Depending on your theme the ideal size is 1200 by 650 pixels',
  };
  return (
    <div>
      <div id="article-details" className="panel panel-default">
        <div className="panel-body">
          <div className="row row-table-sm">
            <div className="col-xs-12 col-table-sm va-middle-sm space-xs-top-3 space-3">
              <div className="h5 heading-margin-0">Article details</div>
              <p className="small text-muted">
                A summary of the article used on overview pages, social and email channels.
                <span>&nbsp;</span>
                <HelpLink url="https://help.storychief.io/en/articles/2085950-create-a-summary-for-your-story">
                  Learn more
                </HelpLink>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <ImageFieldType
                disabled={props.readOnly}
                fieldDefinition={featuredImageFieldDef}
                fieldValue={{
                  value: props.story.featured_image.src,
                  key: 'featured_image',
                }}
                onUpdateCallback={handleUpdateStoryFeaturedImage}
                onAltUpdateCallback={handleUpdateStoryFeaturedImageAlt}
              />
              <FormGroup controlId="excerpt">
                <ControlLabel bsClass="control-label">
                  Excerpt
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="help-description">
                        A summary of your article displayed on overview pages, social and email
                        channels.
                      </Tooltip>
                    }
                  >
                    <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="14" />
                  </OverlayTrigger>
                </ControlLabel>
                <TextEditor
                  placeholder="Add excerpt or use AI to generate"
                  readOnly={props.readOnly}
                  emojiPicker
                  content={props.story.excerpt}
                  onContentChange={handleUpdateStoryExcerpt}
                  formControl
                  formControlAutoHeight
                  showLimitIndicator
                  formControlId="excerpt"
                  assistantSubject={{
                    type: 'StoryExcerpt',
                    model: {
                      __typename: 'Story',
                      id: props.story.id.toString(),
                      title: storyTitlePlainText,
                      content: props.story.content,
                      keywords: props.storyKeywords,
                    },
                  }}
                />
              </FormGroup>
              <FormGroup controlId="author" data-testid="author-input">
                <ControlLabel bsClass="control-label">
                  Author*
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="help-description">The author of the article. Required!</Tooltip>
                    }
                  >
                    <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="14" />
                  </OverlayTrigger>
                </ControlLabel>
                <Select
                  isClearable={false}
                  isLoading={loadingAuthors || loadingStoryAuthorMutation}
                  value={story.author}
                  getOptionLabel={(o) => `${o.fullname} (${o.email})`}
                  getOptionValue={(o) => o.id}
                  isDisabled={props.readOnly}
                  inputId="react-select-author-input"
                  placeholder={story.author ? `${story.author.fullname}` : 'Select'}
                  options={authors}
                  onChange={handleAuthorSelectChange}
                  noOptionsMessage={() => (
                    <span className="select-menu__option--create">
                      <span>Not listed? </span>
                      <button
                        type="button"
                        className="btn btn-chromeless btn-link initial-line-height"
                        onClick={handleOnAddAuthorClick}
                      >
                        Create Author
                      </button>
                    </span>
                  )}
                />
                {StoryChief.accessRight('canManageAuthors') && !props.readOnly && (
                  <span className="help-block">
                    <a href={`${StoryChief.dashboardBasePath}/account/authors`}>Manage authors</a>
                  </span>
                )}
              </FormGroup>
              <FormGroup controlId="categories" data-testid="categories-input">
                <ControlLabel bsClass="control-label">
                  Category
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="help-description">
                        Categories created inside StoryChief are matched with the categories on your
                        personal websites. In case a channel doesn&apos;t support multiple
                        categories the first one is used.
                      </Tooltip>
                    }
                  >
                    <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="14" />
                  </OverlayTrigger>
                </ControlLabel>
                {StoryChief.account.categoriesLocked ? (
                  <Select
                    isMulti
                    inputId="react-select-categories-input"
                    value={story.categories}
                    getOptionLabel={(o) => o.name}
                    getOptionValue={(o) => o.id}
                    isDisabled={props.readOnly}
                    placeholder="Add categories"
                    noOptionsMessage={() => 'No categories available'}
                    options={categories}
                    onChange={handleCategorySelectChange}
                  />
                ) : (
                  <Select
                    type="creatable"
                    inputId="react-select-categories-input"
                    isMulti
                    isMultiCreatable
                    value={story.categories}
                    getOptionLabel={(o) => o.name}
                    getOptionValue={(o) => o.id}
                    getNewOptionData={(value, label) => ({
                      id: value,
                      name: label,
                      __isNew__: true,
                    })}
                    isLoading={loadingStory || loadingCategories || loadingStoryCategoriesMutation}
                    isDisabled={props.readOnly}
                    placeholder="Add categories"
                    noOptionsMessage={() =>
                      categories.length === 0 ? 'Start typing to add your first category' : null
                    }
                    options={categories}
                    onChange={handleCategorySelectChange}
                  />
                )}
                {StoryChief.accessRight('canManageCategories') && !props.readOnly && (
                  <span className="help-block">
                    <a href={`${StoryChief.dashboardBasePath}/account/categories`}>
                      Manage categories
                    </a>
                  </span>
                )}
              </FormGroup>
              <FormGroup controlId="tags" data-testid="tags-input">
                <ControlLabel bsClass="control-label">
                  Tags
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="help-description">
                        Micro-categories for your article used to show related articles.
                      </Tooltip>
                    }
                  >
                    <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="14" />
                  </OverlayTrigger>
                </ControlLabel>
                {StoryChief.account.tagsLocked ? (
                  <Select
                    isMulti
                    inputId="react-select-tags-input"
                    value={story.tags}
                    getOptionLabel={(o) => o.name}
                    getOptionValue={(o) => o.id}
                    isDisabled={props.readOnly}
                    placeholder="Add tags"
                    noOptionsMessage={() => 'No tags available'}
                    options={tags}
                    onChange={handleTagSelectChange}
                  />
                ) : (
                  <Select
                    type="creatable"
                    inputId="react-select-tags-input"
                    isMulti
                    isMultiCreatable
                    value={story.tags}
                    getOptionLabel={(o) => o.name}
                    getOptionValue={(o) => o.id}
                    getNewOptionData={(value, label) => ({
                      id: value,
                      name: label,
                      __isNew__: true,
                    })}
                    isLoading={loadingStory || loadingTags || loadingStoryTagsMutation}
                    isDisabled={props.readOnly}
                    placeholder="Add tags"
                    noOptionsMessage={() =>
                      tags.length === 0 ? 'Start typing to add your first tag' : null
                    }
                    options={tags}
                    onChange={handleTagSelectChange}
                  />
                )}
                {StoryChief.accessRight('canManageTags') && !props.readOnly && (
                  <span className="help-block">
                    <a href={`${StoryChief.dashboardBasePath}/account/tags`}>Manage tags</a>
                  </span>
                )}
              </FormGroup>
              {(!!languageOptions.length || storyLanguage) && (
                <FormGroup controlId="language">
                  <ControlLabel bsClass="control-label">
                    Language
                    {props.story.published && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="help-description">
                            To change the language of your article you need to unpublish it first.
                          </Tooltip>
                        }
                      >
                        <Icon
                          icon="fa:question-circle"
                          inline
                          className="text-muted ml-1"
                          width="14"
                        />
                      </OverlayTrigger>
                    )}
                  </ControlLabel>
                  <Select
                    value={languageOptions.find((l) => l.value === storyLanguage)}
                    isDisabled={
                      props.story.published ||
                      props.readOnly ||
                      loadingUpdateLanguage ||
                      loadingLanguageTranslations
                    }
                    placeholder={languageOptions.length !== 0 ? 'Choose language' : storyLanguage}
                    isClearable={false}
                    options={languageOptions}
                    onChange={handleLanguageSelectChange}
                  />
                  {StoryChief.accessRight('canEditAccount') && (
                    <span className="help-block">
                      <a href={`${StoryChief.dashboardBasePath}/account/languages`}>
                        Manage languages
                      </a>
                    </span>
                  )}
                </FormGroup>
              )}
              <FormGroup controlId="publication_date" className="m-0">
                <ControlLabel bsClass="control-label">
                  Due date (internal use)
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="help-description">
                        Indicates due date for internal use only. Article will not be published
                        automatically.
                      </Tooltip>
                    }
                  >
                    <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="14" />
                  </OverlayTrigger>
                </ControlLabel>
                <div>
                  {!props.readOnly ? (
                    <DateRangePicker
                      startDate={dueAt}
                      onChange={handleDueDateChange}
                      onClear={handleDueDateClear}
                      triggerType="input"
                      inputTriggerPlaceholder="Due date"
                    />
                  ) : (
                    <div className="input-group">
                      <FormControl
                        type="text"
                        placeholder="Due date"
                        value={dueAt ? dueAt.toFormat('MMM d, yy') : ''}
                        disabled
                        autoComplete="off"
                      />
                      <span className="input-group-btn">
                        <div className="btn btn-default btn-default-light disabled">
                          <Icon icon="clarity:calendar-line" inline width="16" />
                        </div>
                      </span>
                    </div>
                  )}
                </div>
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
      <div id="seo-settings" className="panel panel-default">
        <div className="panel-body">
          <div className="row row-table-sm">
            <div className="col-xs-12 col-table-sm va-middle-sm space-xs-top-3 space-3">
              <div className="h5 heading-margin-0">Article SEO settings</div>
              <p className="small text-muted">
                Customize how this article will appear on search-engine result pages.
                <span>&nbsp;</span>
                <HelpLink url="https://help.storychief.io/en/articles/1514429-optimize-your-story-for-seo-and-readability">
                  Learn more
                </HelpLink>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="h6 heading-margin-top-0">Search-engine result preview</div>
              <StoryTeaserCardSeo />
            </div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <FormGroup controlId="seoTitle">
                <ControlLabel bsClass="control-label">
                  SEO Title
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="help-description">
                        This is the first thing people see when they find your article in search
                        engines. So keep your title short, quick and eye catching
                      </Tooltip>
                    }
                  >
                    <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="14" />
                  </OverlayTrigger>
                </ControlLabel>
                <TextEditor
                  readOnly={props.readOnly}
                  emojiPicker
                  placeholder={titlePlaceholder}
                  limit={580}
                  limitInfo={getSeoAlertMsg(580, 'pixels')}
                  limitType="size"
                  limitSizeSelector="story-teaser-title-hidden"
                  showLimitIndicator
                  content={seoTitle || ''}
                  onContentChange={onSeoTitleChange}
                  debounceContentChange={false}
                  formControl
                  formControlAutoHeight
                  formControlId="seoTitle"
                  assistantSubject={{
                    type: 'StorySeoTitle',
                    model: {
                      __typename: 'Story',
                      id: props.story.id.toString(),
                      title: storyTitlePlainText,
                      content: props.story.content,
                      keywords: props.storyKeywords,
                    },
                  }}
                />
                <HelpBlock>Defaults to the article title if left blank.</HelpBlock>
              </FormGroup>
              <FormGroup controlId="seoSlug">
                <ControlLabel bsClass="control-label">
                  Slug
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="help-description">
                        Change the slug so it is easy to read and includes words that describe the
                        content of the article
                      </Tooltip>
                    }
                  >
                    <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="14" />
                  </OverlayTrigger>
                </ControlLabel>
                <FormControl
                  disabled={props.readOnly}
                  id="seoSlug"
                  type="text"
                  placeholder={props.story.slug}
                  name="seoSlug"
                  componentClass="input"
                  value={seoSlug}
                  onChange={onTextFormControlChange}
                />
                <HelpBlock>
                  Defaults to your website&apos;s slug generation if left blank.
                </HelpBlock>
              </FormGroup>

              <FormGroup controlId="seoDescription" className="m-0">
                <ControlLabel bsClass="control-label">
                  Meta Description
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="help-description">
                        Draw visitors to your website by giving them more insights about this
                        article
                      </Tooltip>
                    }
                  >
                    <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="14" />
                  </OverlayTrigger>
                </ControlLabel>
                <TextEditor
                  readOnly={props.readOnly}
                  emojiPicker
                  placeholder={props.story.excerpt || ''}
                  limit={156}
                  limitInfo={getSeoAlertMsg(156)}
                  showLimitIndicator
                  debounceContentChange={false}
                  content={seoDescription || ''}
                  onContentChange={onSeoDescriptionChange}
                  formControl
                  formControlAutoHeight
                  formControlId="seoDescription"
                  assistantSubject={{
                    type: 'StorySeoDescription',
                    model: {
                      __typename: 'Story',
                      id: props.story.id.toString(),
                      title: storyTitlePlainText,
                      content: props.story.content,
                      keywords: props.storyKeywords,
                    },
                  }}
                />
                <HelpBlock className="mb-0">
                  Defaults to the article excerpt if left blank.
                </HelpBlock>
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DetailsBlock.propTypes = propTypes;
DetailsBlock.defaultProps = defaultProps;
DetailsBlock.fragments = fragments;

function mapStateToProps(state, ownProps) {
  return {
    story: state.story,
    lock: state.lock,
    storyId: state.story.id,
    storyTitle: state.story.title,
    storyKeywords: state.story.keywords,
    storySeoTitle: state.story.seo_title,
    storySeoDescription: state.story.seo_description,
    storySeoSlug: state.story.seo_slug,
    storyAnalysis: state.story.analysis,
    destinations: state.destinations,
    readOnly: ownProps.readOnly || getModelIsLocked(state),
    // We set an empty value for accessToken in preview mode because we still want to validate its
    // value on edit mode and because it should not used in preview mode.
    accessToken: ownProps.readOnly ? '' : state.lock.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateStorySeoTitle,
      updateStorySeoDescription,
      updateStorySeoSlug,
      updateStoryExcerpt,
      updateStoryFeaturedImage,
      updateStoryFeaturedImageAlt,
      setStoryDueDate,
      addStoryTagAsync,
      addStoryCategoryAsync,
      addStoryAuthorAsync,
      updateStoryHasUnconfirmedChanges,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsBlock);
