import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import useModal from '@/modals/hooks/useModal';
import checkOfflineNetworkError from '@/toast-notifications/utils/checkOfflineNetworkError';
import tryParseJson from '@/storychief/utils/tryParseJson';

const propTypes = {
  isError: PropTypes.bool.isRequired,
  response: PropTypes.shape({
    status: PropTypes.number,
    text: PropTypes.string,
  }),
};
const defaultProps = {
  response: null,
};

const ERROR_STATUS_CODES = {
  validation: 422,
  auth: 401,
};

function StoryErrors({ isError, response }) {
  const modal = useModal('ErrorModal');
  const statusCode = response?.status || null;

  function checkStoryErrors() {
    if (isError && navigator.onLine) {
      const message = tryParseJson(response.text)?.parsed?.message;

      if (statusCode === ERROR_STATUS_CODES.validation && message) {
        toast.error(message);
      } else {
        modal.show({
          props: {
            errorType: statusCode === ERROR_STATUS_CODES.auth ? 'auth' : 'default',
            response: response || undefined,
          },
        });
      }
    }
  }

  useEffect(() => {
    checkStoryErrors();
    checkOfflineNetworkError();
  }, [isError, statusCode]);

  return null;
}

function mapStateToProps(state) {
  return {
    isError: state.ui.status === 'error',
    response: state.ui.status_response,
  };
}

StoryErrors.propTypes = propTypes;
StoryErrors.defaultProps = defaultProps;

export default connect(mapStateToProps, null)(StoryErrors);
