import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditorScContent from '@/editor/components/EditorScContent';
import EditorScTitle from '@/editor/components/EditorScTitle';
import useStoryEditorSidebars from '@/stories/hooks/useStoryEditorSidebars';
import useStoryEditorData from '@/stories/hooks/useStoryEditorData';
import getPreviewStory from '@/stories/actions/getPreviewStory';

const propTypes = {};
const defaultProps = {};

function EditorPreviewSc() {
  const dispatch = useDispatch();
  const story = useSelector((state) => state.story);
  const storyEditorData = useStoryEditorData();
  const storyEditorSidebars = useStoryEditorSidebars();

  useEffect(() => {
    if (storyEditorData.fetchEndpoint && story.id && window.Echo) {
      window.Echo.channel(`story.${story.id}`).listen('StoryUpdated', () => {
        dispatch(getPreviewStory(storyEditorData.fetchEndpoint));
      });
    }
  }, []);

  return (
    <div id="sc-editor" className="sc-editor">
      <div className="editor-title caret-transparent">
        <EditorScTitle
          preview
          sidebars={storyEditorSidebars}
          modelType="Story"
          modelId={story.id.toString()}
          content={story.content}
          title={story.title}
        />
      </div>

      <div className="editor-content caret-transparent">
        <EditorScContent
          preview
          sidebars={storyEditorSidebars}
          blockButtons={[]}
          inlineButtonsRight={[]}
          modelId={story.id.toString()}
          modelType="Story"
          language={story.language}
          content={story.content}
          title={story.title}
        />
      </div>
    </div>
  );
}

EditorPreviewSc.propTypes = propTypes;
EditorPreviewSc.defaultProps = defaultProps;

export default EditorPreviewSc;
