import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getDestinationUrl from '../../destinations/utils/getDestinationUrl';
import getStorySlug from '../utils/getStorySlug';
import getTitlePlainText from '@/editor/utils/getTitlePlainText';

const propTypes = {
  story: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    storyExcerpt: PropTypes.string,
    featured_image: PropTypes.shape({
      src: PropTypes.string,
      status: PropTypes.string,
    }),
    primary_destination: PropTypes.shape({}),
  }).isRequired,
  storyTitle: PropTypes.string.isRequired,
  storyExcerpt: PropTypes.string,
  storyPrimaryDestination: PropTypes.shape({}),
  storySeoTitle: PropTypes.string.isRequired,
  storySeoDescription: PropTypes.string.isRequired,
};

const defaultProps = {
  storyExcerpt: '',
  storyPrimaryDestination: null,
};

function StoryTeaserCardSeo(props) {
  const {
    story,
    storyTitle,
    storyExcerpt,
    storyPrimaryDestination,
    storySeoTitle,
    storySeoDescription,
  } = props;

  const url = getDestinationUrl(storyPrimaryDestination, story);
  const slug = getStorySlug(story);
  const publishUrl = `${url}/${slug}`;
  const title =
    storySeoTitle && storySeoTitle !== '' ? storySeoTitle : getTitlePlainText(storyTitle);
  const description =
    storySeoDescription && storySeoDescription !== '' ? storySeoDescription : storyExcerpt;

  return (
    <div>
      <div className="story-teaser story-teaser--card story-teaser--card--seo">
        <span className="story-teaser-title-hidden">{title}</span>
        <span className="story-teaser-title">{title}</span>
        <span className="story-teaser-url">{publishUrl}</span>
        <span className="story-teaser-excerpt">{description}</span>
      </div>
    </div>
  );
}

StoryTeaserCardSeo.propTypes = propTypes;
StoryTeaserCardSeo.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    story: state.story,
    storyTitle: state.story.title,
    storyExcerpt: state.story.excerpt,
    storyPrimaryDestination: state.story.primary_destination,
    storySeoTitle: state.story.seo_title,
    storySeoDescription: state.story.seo_description,
  };
}

export default connect(mapStateToProps, null)(StoryTeaserCardSeo);
