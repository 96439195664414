import { gql } from '@apollo/client';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useStoryEditorSidebars from '@/stories/hooks/useStoryEditorSidebars';

const fragments = {
  story: gql`
    fragment UseStorySidebarOpenOnLoadFragment on Story {
      __typename
      id
      brief {
        __typename
        id
      }
      reviews {
        __typename
        id
        model_type
        model_id
        reviewer(trashed: WITH) {
          __typename
          id
          firstname
          lastname
          email
          profile_picture
        }
        requester(trashed: WITH) {
          __typename
          id
          firstname
          lastname
          profile_picture
        }
        can {
          delete
        }
        reviewer_id
        reviewer_email
        requester_id
        request_message
        approval_message
        accepted_at
        declined_at
        deleted_at
        created_at
        updated_at
      }
    }
  `,
};

type UseStorySidebarOpenOnLoadType = {
  storyId: string;
  isOpenReview: boolean;
  loading: boolean;
  hasBrief: boolean;
  isPreview: boolean;
  toggleModal?: (modal: string) => void;
};

function useStorySidebarOpenOnLoad({
  storyId,
  isOpenReview,
  hasBrief,
  loading,
  isPreview,
  toggleModal = () => {},
}: UseStorySidebarOpenOnLoadType): void {
  const storyEditorSidebars = useStoryEditorSidebars();
  const location = useLocation();

  function openCommentsSidebar() {
    storyEditorSidebars.replace({
      id: 'CommentsSidebar',
      props: {
        modelId: storyId.toString(),
        modelType: 'Story',
      },
    });
  }

  function openBriefSidebar() {
    storyEditorSidebars.replace({
      id: 'StoryBriefSidebar',
    });
  }

  function openReviewsSidebar() {
    storyEditorSidebars.replace({
      id: 'ReviewsSidebar',
      props: {
        modelId: storyId.toString(),
        modelType: 'Story',
      },
    });
  }

  useEffect(() => {
    if (!loading) {
      const queryParams = new URLSearchParams(location.search);

      if (queryParams.has('comment')) {
        openCommentsSidebar();
      } else if (queryParams.has('modal') && !isPreview) {
        openBriefSidebar();
        toggleModal(queryParams.get('modal'));
      } else if (isOpenReview) {
        openReviewsSidebar();
      } else if (hasBrief) {
        openBriefSidebar();
      }
    }
  }, [loading]);
}

useStorySidebarOpenOnLoad.fragments = fragments;

export default useStorySidebarOpenOnLoad;
